<template>
 <!--  <base-section id="news">
    <base-section-heading title="Educación Continua">
      Consulta las nuevas actividades academicas que tenemos para nuestros miembros
    </base-section-heading>
    <v-container>
      <v-row>
        <v-col
          v-for="(news, i) in articles"
          :key="i"
          cols="12"
          md="4"
        >
          <news-card
            v-bind="news"
            :src="require(`@/assets/article-${i + 1}.jpg`)"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>  -->
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-tooltip bottom color="grey">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="float: right; margin: 4px 50px -42px 0px; z-index: 2;"
              fab
              dark
              x-small
              color="grey"
              :href="path_citatorio"
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-download
              </v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px;"><b>VER DOCUMENTO</b></span>
        </v-tooltip>
        <v-btn
          style="float: right; margin: 4px 10px -42px 0px; z-index: 2;"
          fab
          dark
          x-small
          color="grey"
          @click="dialog = false"
        >
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
        <v-img
          width="120%"
          :src="require('@/assets/Citatorio a Asamblea General 2024.png')"
          class="white--text"
        ></v-img>
        <v-card-actions class="justify-center">
          <v-btn
            :href="path_citatorio"
            target="_blank"
            class="white--text"
            color="red darken-1"
            small
          >
            <b>DESCARGAR</b>
          </v-btn>
          <v-btn
            class="white--text"
            color="red darken-1"
            @click="dialog = false"
            small
          >
            <b>Cerrar</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'SectionNews',
    components: {
      NewsCard: () => import('@/components/news/Card'),
    },
    mounted() {
      setTimeout(function() {
        this.dialog = true
      }.bind(this), 1000);
    },
    data: () => ({
      dialog: false,
      documento:" @/assets/compac/home-compac_reducida2.png",
      path_citatorio:process.env.VUE_APP_BASE_URL+'/citatorios/Citatorio a Asamblea General 2024.pdf',
    }),
  }
</script>
